.invitation-count{
    position: absolute;
    right: 6px;
    top: -2px;
    color: #ffffff;
    background: #FF5858;
    font-size: 0.75rem;
    font-weight: 800;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}