*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
img {
    max-width: 100%;
}
ul {
    list-style: none;
}

body {
    font-family: 'Asap', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// * @default {
//     *    // extra-small
//     *    xs: 0,
//     *    // small
//     *    sm: 600,
//     *    // medium
//     *    md: 900,
//     *    // large
//     *    lg: 1200,
//     *    // extra-large
//     *    xl: 1536,
//     * }



// common layout
.site-container{
    position: relative;
}

.main-inner-contianer {
    position: relative;
    padding: 0rem 0 5rem;
    min-height: calc(100svh - (112px + 140.8px + 57.8px + 3.25rem)); // 112px-header, 140.8px-footer, 57.8px-tab header menu, 3.25rem-margin bottom for tab header menu
}

// theme heading css

.ev-theme-heading {
    position: relative;
    &:after {
        content: none;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -18px;
        height: 4px;
        width: 70px;
        display: inline-block;
        background: $color-primary;
        @include respond-to(theme_Sm){ 
           content: '';
        }
    }
}



// tabs list styles
.theme-tabs-list {
    // display: inline-flex;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 3.5px;
        width: 100%;
        background-color: rgba(84, 88, 90, 0.5);
    }
    .MuiTabs-indicator {
        height: 3.5px;
    }
}


//   Notification variable color modification

:root {
    --toastify-color-success: #2e813e;
    --toastify-color-warning: #eb9719;
    --toastify-color-error: #bd362f;
  }
  

//   disabled mui typgraphy
.MuiTypography-root {
    &[disabled]  {
    pointer-events: none;
    opacity: 0.55;
    cursor: text;
   }
}


.ev-theme-table {
    thead {
        th {
            &.MuiTableCell-root {
                padding: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                &:first-of-type {
                    min-width: 8rem;
                }
            }
        }
    }
}

// chart common css -

.bi-embedded {
    &.energy-use-by-hour-embedded {
        height: 450px;
      }
}

.incentive-settings-table{
    .MuiTable-root .MuiTableCell-root:first-of-type {
            min-width: 13rem;
            text-align: left;
        }
    .MuiTable-root .MuiTableCell-root {
        text-align: center;
    }
}

.savings-report-table{
    .MuiTableRow-root th{
        padding: 0;
    }
    .MuiTableBody-root{
        tr{
            td:nth-child(2){
                text-align: end;
            }

            td:last-child {
                text-align: start;
            }
        }
    }
}
/* HTML: <div class="loader"></div> */
.progress-loader {
    width: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }

  @keyframes l5 {
      0%  {box-shadow: 20px 0 #2e813e, -20px 0 #2e813e20;background: #2e813e }
      33% {box-shadow: 20px 0 #2e813e, -20px 0 #2e813e20;background: #2e813e20}
      66% {box-shadow: 20px 0 #2e813e20,-20px 0 #2e813e; background: #2e813e20}
      100%{box-shadow: 20px 0 #2e813e20,-20px 0 #2e813e; background: #2e813e }
  }
  
.preview-email {

    /* Reset the reset for all elements within the editor */
    * {
        margin: revert;
        padding: revert;
    }

    /* Keep box-sizing: border-box for consistency */
    * {
        box-sizing: border-box;
    }

    /* Specific element adjustments */
    p {
        margin-bottom: 1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    ul,
    ol {
        margin-left: 1em;
        padding-left: 1em;
        list-style: unset;
    }

    .MuiPaper-root {
        min-height: 300px;
        min-width: 300px;
        width: 600px;

        .MuiDialogContent-root {
            display: flex;
            padding: 0;
        }
    }
}

.authentication-modal {
    .MuiBackdrop-root {
        background-color: rgba(240, 248, 255);
        backdrop-filter: blur(10px);
    }
}

.timeout-modal {
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(2px);
    }
}