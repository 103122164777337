$theme-color-white: #fff !default;
$theme-color-dark: #000 !default;
$theme-color-dark-light: #242424 !default;

$color-primary : #2E813E !default;
$color-primary-dark : #348D3D !default;
$color-primary-light: #F4FFF5 !default;
$color-primary-thin-Light: #F5F9F5 !default;

$color-warning: #EB9719 !default;
$color-success: $color-primary !default;
// $color-secondary-300 : #77B89A !default;
$color-danger: #DA0606 !default;
// $color-danger-light: #D6819E !default;
 
$color-gray: #9E9D9D !default;
$color-gray-dark: #54585A !default;
// $color-gray-light: #AEAEAE !default;
// $color-gray-extra-light: #D1D1D1 !default;